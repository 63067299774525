import { ParsedUrlQuery } from 'querystring';

import { useRouter } from 'next/router';
import { Fragment, useEffect } from 'react';

import nextI18nextConfig from '../../next-i18next.config';

import languageDetector from './languageDetector';

import { Locale } from '@/types';

export function getLocalizedRoute<T extends ParsedUrlQuery>(
  {
    query,
    pathname,
  }: {
    query: T;
    pathname: string;
  },
  locale: Locale,
) {
  return Object.keys(query).reduce((href: string, param: string) => {
    return href.replace(`[${param}]`, param === 'locale' ? locale : (query?.[param] as string) || '');
  }, pathname || '');
}

function getCurrentLocale(locale: string) {
  if (['uk', 'ua'].includes(locale)) {
    return 'ua';
  } else {
    return 'en';
  }
}

export const useRedirect = (to?: string) => {
  const router = useRouter();
  const path = to || router.asPath;

  useEffect(() => {
    if (localStorage.getItem('localeChecked') !== 'true') {
      // @ts-expect-error Depends on the browser we use different property
      const language = getCurrentLocale(window.navigator.language || window.navigator?.userLanguage);
      languageDetector.cache?.(language);
      localStorage.setItem('localeChecked', 'true');
    }
  }, []);

  // language detection
  useEffect(() => {
    const isDynamicPath = new RegExp(/\[[a-z]{1,}\]/).test(router.pathname);
    if ((Object.keys(router.query).length && isDynamicPath) || !isDynamicPath) {
      const detectedLng = languageDetector.detect();
      if (path?.startsWith('/' + detectedLng) && router.route === '/404') {
        // prevent endless loop
        router.replace('/' + detectedLng + router.route);
        return;
      }
      languageDetector?.cache?.(detectedLng || nextI18nextConfig.i18n.defaultLocale);
      router.replace({
        pathname: `/[locale]${router.pathname.endsWith('/') ? router.pathname : router.pathname + '/'}`,
        query: { ...router.query, locale: detectedLng },
      });
    }
  });

  return <Fragment />;
};

// eslint-disable-next-line react/display-name
export const getRedirect = (to: string) => () => {
  useRedirect(to);
  return <Fragment />;
};

export default function Redirect() {
  useRedirect();
  return <Fragment />;
}
